"use client"
import { cn } from "@/lib/utils"
import { useRouter } from "next/navigation"
import React from "react"

const CategoryBanner = React.forwardRef<
  HTMLDivElement,
  {
    className?: string
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  }
>(({ className, setIsOpen }, ref) => {
  const router = useRouter()
  const handleLeave = () => {
    setIsOpen(false)
  }
  return (
    <div
      ref={ref}
      onMouseLeave={handleLeave}
      className={cn("fixed top-0 left-0 right-0", className)}
    >
      <div className="h-20 w-full" />
      <section
        className="w-full h-[30vh] flex gap-4 bg-white items-center px-4 justify-between"
        style={{ boxShadow: "0px 6px 27.3px -9px rgba(0, 0, 0, 0.15)" }}
      >
        <div
          className="flex flex-col relative items-center rounded-lg h-[85%] w-[24%] hover:bg-[#F9F9F9] border border-[#E3E3E3 cursor-pointer"
          onClick={() => {
            router.push("/all-products/categories/Macbooks")
          }}
        >
          <div className="flex flex-col w-full mt-[8%] pl-6 gap-y-1">
            <h3 className="font-gilroySemiBold text-lg/3">Macbooks</h3>
            <p className="font-gilroyMedium text-sm">The Sleekest Machine</p>
          </div>
          <img
            src="https://storage.googleapis.com/cdn-edify-assets-1743752952/media/category-banner/mac.webp"
            alt="mac-banner"
            className="object-contain absolute bottom-0"
            width={280}
            height={200}
          />
        </div>

        <div
          className="flex flex-col relative items-center rounded-lg h-[85%] w-[24%] hover:bg-[#F9F9F9] border border-[#E3E3E3] cursor-pointer"
          onClick={() => {
            router.push("/all-products/categories/Windows")
          }}
        >
          <div className="flex flex-col w-full mt-[8%] pl-6 gap-y-1">
            <h3 className="font-gilroySemiBold text-lg/3">Windows</h3>
            <p className="font-gilroyMedium text-sm">Your Daily Workstation</p>
          </div>
          <img
            src="https://storage.googleapis.com/cdn-edify-assets-1743752952/media/category-banner/windows.webp"
            alt="mac-banner"
            className="object-contain absolute bottom-0"
            width={280}
            height={200}
          />
        </div>

        <div className="flex flex-col justify-between rounded-lg h-[85%] w-[24%] cursor-pointer">
          <div
            onClick={() => {
              router.push("/all-products/categories/Mini PCs")
            }}
            className="flex flex-row relative items-center rounded-lg h-[47%] w-[100%] hover:bg-[#F9F9F9] border border-[#E3E3E3]"
          >
            <div className="flex flex-col w-[60%] pl-6 gap-y-1">
              <h3 className="font-gilroySemiBold text-lg/3">Mini PCs</h3>
              <p className="font-gilroyMedium text-sm">
                Your Compact Powerhouse
              </p>
            </div>
            <img
              src="https://storage.googleapis.com/cdn-edify-assets-1743752952/media/category-banner/cpu.webp"
              alt="mac-banner"
              className="object-contain absolute bottom-0 right-0"
              width={130}
              height={90}
            />
          </div>

          <div
            onClick={() => {
              router.push("/all-products/categories/Accessories")
            }}
            className="flex flex-row relative items-center rounded-lg h-[47%] w-[100%] hover:bg-[#F9F9F9] border border-[#E3E3E3]"
          >
            <div className="flex flex-col w-[60%] pl-6 gap-y-1">
              <h3 className="font-gilroySemiBold text-lg/3">Accessories</h3>
              <p className="font-gilroyMedium text-sm">Gear up your devices</p>
            </div>
            <img
              src="https://storage.googleapis.com/cdn-edify-assets-1743752952/media/category-banner/keyboard.webp"
              alt="mac-banner"
              className="object-contain absolute bottom-0 right-0"
              width={135}
              height={100}
            />
          </div>
        </div>

        <div
          className="flex flex-col relative items-center rounded-lg h-[85%] w-[24%] hover:bg-[#F9F9F9] border border-[#E3E3E3] cursor-pointer"
          onClick={() => {
            router.push("/all-products/categories/Desktops")
          }}
        >
          <div className="flex flex-col w-full mt-[8%] pl-6 gap-y-1">
            <h3 className="font-gilroySemiBold text-lg/3">Desktops</h3>
            <p className="font-gilroyMedium text-sm">Your All-In-One Machine</p>
          </div>
          <img
            src="https://storage.googleapis.com/cdn-edify-assets-1743752952/media/category-banner/desktop.webp"
            alt="mac-banner"
            className="object-contain absolute bottom-0"
            width={280}
            height={200}
          />
        </div>
      </section>
    </div>
  )
})

CategoryBanner.displayName = "CategoryBanner"

export { CategoryBanner }
